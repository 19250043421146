import React, { useState } from "react"
import { DefaultHeader } from "components/headers/default-header"
import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { FollowBtn } from "components/follow-btn"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { BrandLogo } from "components/brand-logo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLine,
  faTwitterSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons"
import {
  faTrophy,
  faTicketAlt,
  faQuestionCircle,
  faBook,
  faChartBar,
  faMapMarkedAlt,
  faEnvelopeOpenText,
  faWindowMaximize,
  faPaperPlane,
  faGifts,
  faGift,
  faImages,
  faDatabase,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons"

const Index = () => {
  const [isShowFollowBtn, setIsShowFollowBtn] = useState(false)
  useScrollPosition(({ prevPos, currPos }) => {
    const isVisible = currPos.y > -250 ? false : true
    setIsShowFollowBtn(isVisible)
  })
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo pagetitle="PARKLoT" />
        <main className="main">
          <section className="hero">
            <div className="hero__container">
              <div className="hero__inner">
                <div className="hero__catch">
                  {/* FIXME: コピーは仮 */}
                  <h1 className="hero__title">
                    Gain followers and
                    <br />
                    Accelerate sales promotion
                    <br />
                    SNS Campaign Tools
                  </h1>
                  <p className="hero__lead">
                    Instant Win, Coupon Distribution, UGC Use!
                    <br />
                    You can do all sorts of SNS campaigns!
                  </p>
                  <div className="hero__icons">
                    <FontAwesomeIcon
                      icon={faTwitterSquare}
                      className="icon--twitter"
                    />
                    <FontAwesomeIcon
                      icon={faInstagramSquare}
                      className="icon--ig"
                    />
                    <FontAwesomeIcon icon={faLine} className="icon--line" />
                  </div>
                  <div className="hero__features">
                    <StaticImage
                      layout="constrained"
                      alt="Extensive Functions"
                      src="../../images/index/words_01.png"
                    />
                    <StaticImage
                      layout="constrained"
                      alt="From 50,000 yen"
                      src="../../images/index/words_02.png"
                    />
                    <StaticImage
                      layout="constrained"
                      alt="Held in as little as one day"
                      src="../../images/index/words_03.png"
                    />
                    <StaticImage
                      layout="constrained"
                      alt="cost-effective"
                      src="../../images/index/words_04.png"
                    />
                  </div>
                </div>
                <figure className="hero__images">
                  {/* FIXME: altは正式決定したコピーに変更 */}
                  <StaticImage
                    layout="constrained"
                    alt="Support from planning to execution of SNS campaign measures!"
                    src="../../images/index/fv_mock.png"
                  />
                </figure>
              </div>
              <div className="hero__btn-container">
                <a
                  className="ga-download-top hero__btn btn--brand"
                  href="https://hub.park-lot.com/document-download"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download the 3-minute&nbsp;
                  <br />
                  document (free)
                </a>
              </div>
            </div>
          </section>
          <BrandLogo />
          <section className="trouble index">
            <div className="trouble__container l-container">
              <h2 className="index__title">
                Do you have any of&nbsp;
                <br />
                these problems?
              </h2>
              <ul className="trouble__list">
                <li className="trouble__item">
                  Limited budget and frequency of implementation,&nbsp;
                  <br />
                  and it takes time to plan.
                  <br />
                  <figure className="trouble__img-wrapper">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="It takes time to plan"
                      src="../../images/index/person-1.png"
                    />
                  </figure>
                </li>
                <li className="trouble__item">
                  Want to reduce the hassle of campaign operations!
                  <figure className="trouble__img-wrapper">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="Want to reduce the hassle of campaign operations!"
                      src="../../images/index/person-2.png"
                    />
                  </figure>
                </li>
                <li className="trouble__item">
                  Want effective promotion in less time!
                  <figure className="trouble__img-wrapper">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="Want effective promotion in less time!"
                      src="../../images/index/person-3.png"
                    />
                  </figure>
                </li>
                <li className="trouble__item">
                  Campaigns do not lead to sales.
                  <figure className="trouble__img-wrapper">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="Campaigns do not lead to sales."
                      src="../../images/index/person-4.png"
                    />
                  </figure>
                </li>
              </ul>
            </div>
          </section>
          <section className="solution">
            <div className="solution__container l-container">
              <h2 className="solution__title">
                {/* <span className="solution__small">PARKLoT</span> */}
                <span className="solution__row">
                  <StaticImage
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="PARKLoT"
                    src="../../images/index/solution-logo.png"
                    className="solution__logo"
                  />
                  will solve&nbsp;
                </span>
                your problems!!
              </h2>
              <div className="solution__card-container">
                <div className="solution__card">
                  <h3 className="solution__subtitle">
                    Automates tedious tasks!
                  </h3>
                  <div className="solution__img">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="面倒な作業は自動化！"
                      src="../../images/index/solution-1.png"
                    />
                  </div>
                  <p className="solution__text">
                    PARKLoT automates the entire process of&nbsp;
                    <strong className="index__strong">drawing lots</strong>
                    ,&nbsp;
                    <strong className="index__strong">
                      collecting application information
                    </strong>
                    , and&nbsp;
                    <strong className="index__strong">notifying winners</strong>
                    !
                    <br />
                    It reduces time spent on administrative tasks and creates
                    time for essential value creation, such as communication
                    with customers.
                  </p>
                </div>
                <div className="solution__card">
                  <h3 className="solution__subtitle">
                    Immediately upon thinking of it, do it!
                  </h3>
                  <div className="solution__img">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="Immediately upon thinking of it, do it!"
                      src="../../images/index/solution-2.png"
                    />
                  </div>
                  <p className="solution__text">
                    Campaign starts in as little as&nbsp;
                    <strong className="index__strong">10 minutes!</strong>
                    <br />
                    This is a great way to promote your services and products in
                    a timely manner without missing opportunities, which&nbsp;
                    <strong className="index__strong">
                      will lead to immediate results.
                    </strong>
                  </p>
                </div>
                <div className="solution__card">
                  <h3 className="solution__subtitle">
                    Improvements and additions, as many times as you want!
                  </h3>
                  <div className="solution__img">
                    <StaticImage
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="Improvements and additions, as many times as you want!"
                      src="../../images/index/solution-3.png"
                    />
                  </div>
                  <p className="solution__text">
                    Campaigns should combine short-term and medium- to long-term
                    measures!
                    <br />
                    PARKLoT's campaigns&nbsp;
                    <strong className="index__strong">
                      can be conducted as many times as you like on a monthly
                      basis&nbsp;
                    </strong>
                    , allowing you to run effective campaigns and&nbsp;
                    <strong className="index__strong">maximize LTV</strong>.
                  </p>
                </div>
              </div>
              <div className="solution__flow flow">
                <div className="flow__text-container">
                  <div className="flow__text-box">
                    <h3 className="flow__title">
                      With a fixed price plan,
                      <br />
                      you can run a&nbsp;
                      <br />
                      <strong className="index__strong">
                        completely unlimited
                        <small className="index__small">*</small>
                      </strong>
                      &nbsp;campaign.
                    </h3>
                    <p className="index__text">
                      Generally, when people try to run a campaign on Twitter,
                      they tend to spend too much time planning because of the
                      limited budget and number of times they can run it, and
                      even if the response is not good, they tend to hesitate to
                      run it again because of the large time and budget costs.
                    </p>
                    <p className="index__text">
                      With PARKLoT, you can conduct a campaign as soon as you
                      think of it, or by trial and error while watching the
                      response to your campaign.
                      <br />
                      <strong className="index__strong">
                        You can take advantage of opportunities to promote your
                        services and products.
                      </strong>
                    </p>
                    <p className="index__text flow__detail">
                      In addition, when improvements are made, time related to
                      securing budgets and contracts can be cut, so&nbsp;
                      <strong className="index__strong">
                        the cycle toward results is not slowed down and the
                        campaign can focus on the campaign.
                      </strong>
                    </p>
                    <p className="flow__notes index__notes">
                      *Restrictions may apply depending on plan and usage
                    </p>
                  </div>
                  <div className="flow__card">
                    <p className="flow__text">
                      Cut down on time related to internal reviews and contracts
                    </p>
                    <p className="flow__text">
                      PDCA cycles for&nbsp;
                      <br />
                      results, fast!
                    </p>
                    <div className="flow__img--sp">
                      <StaticImage
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="PDCA cycles for results"
                        src="../../images/index/flow-sp.png"
                      />
                    </div>
                    <div className="flow__img--pc">
                      <StaticImage
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="fast!"
                        src="../../images/index/flow-pc.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="feature index">
            <div className="feature__container l-container">
              <h2 className="index__title">
                Campaigns that can be&nbsp;
                <br />
                implemented in PARKLoT
              </h2>
              <p className="index__lead">
                Spreading the appeal of products&nbsp;
                <br />
                and services through a variety of campaigns.
              </p>
              <p className="index__lead">
                Increase the number of fans by enjoying&nbsp;
                <br />
                exciting campaigns with your customers.
              </p>
              <ul className="feature__list">
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faTrophy}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">Instant win</p>
                  <p className="feature__item--text">
                    Results will be notified by DM.&nbsp;You can check the
                    results of the lottery without the need for Twitter
                    verification!&nbsp;The very popular "win on the spot"
                    policy.
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faTicketAlt}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">Coupon distribution</p>
                  <p className="feature__item--text">
                    Helping you attract customers to your physical
                    store!&nbsp;Distributed three types of coupons:
                    "Cancelable," "Timed," and "Unlimited for a period of time."
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">
                    Quizzes, diagnostics, mini-games
                  </p>
                  <p className="feature__item--text">
                    It's a great way to get people to visit your
                    site!&nbsp;Campaigns that can create even more excitement
                    such as scratch, slots, etc.
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">
                    Automatic Gift Certificate Sending
                  </p>
                  <p className="feature__item--text">
                    Send digital gift certificates to winners via DM!&nbsp;The
                    popular Twitter Follow RT Campaign will be held immediately!
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faMobileAlt}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">Twitter Operation</p>
                  <p className="feature__item--text">
                    From automated appointment posting, RT campaigns, to sending
                    DMs on behalf of our clients!&nbsp;To attract Twitter
                    customers with results.
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faChartBar}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">
                    Retweet user collection
                  </p>
                  <p className="feature__item--text">
                    Retrieve all retweet users.&nbsp;Supporting effective
                    campaign planning by utilizing for various analyses!
                  </p>
                </li>
              </ul>
              <div className="option">
                <h3 className="option__title">
                  Plus a wide variety of free options
                </h3>
                <ul className="option__list">
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faMapMarkedAlt}
                        className="option__icon"
                      />
                    </div>
                    Shipping Address Collection
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faEnvelopeOpenText}
                        className="option__icon"
                      />
                    </div>
                    Automatically sent to winners
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faWindowMaximize}
                        className="option__icon"
                      />
                    </div>
                    Leading to the website
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon icon={faLine} className="option__icon" />
                    </div>
                    Leading to LINE
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon icon={faBook} className="option__icon" />
                    </div>
                    Document distribution
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faGifts}
                        className="option__icon"
                      />
                    </div>
                    Setting the probability of winning for each gift
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon icon={faGift} className="option__icon" />
                    </div>
                    Election Criteria Settings
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faImages}
                        className="option__icon"
                      />
                    </div>
                    Winning and losing image custom
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faDatabase}
                        className="option__icon"
                      />
                    </div>
                    Data collection and CSV export
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <div className="contact">
            <p className="contact__text">
              Download the 3-minute PARKLoT&nbsp;
              <br />
              Success Storybook now!
            </p>
            <a
              className="ga-contact-middle contact__link btn-white"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              Download documents (free)
            </a>
          </div>
          <section className="case">
            <div className="case__container l-container">
              <h2 className="index__title">Case Studies</h2>
              <p className="index__lead">
                PARKLoT delivers a CPF&nbsp;
                <br />
                that is unthinkable with Twitter&nbsp;
                <br />
                Instant Win until now.
              </p>
              <div className="case__item-container">
                <div className="case__item">
                  <div className="case__head">
                    <div className="case__img-wapper">
                      <StaticImage
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="SUTENAI Co."
                        src="../../images/index/case-sutenai.png"
                        maxwidth="155px"
                      />
                    </div>
                  </div>
                  <div className="case__body">
                    <h3 className="case__title">
                      SUTENAI <span className="case__small">Co.</span>
                    </h3>
                    <p className="case__text">
                      The Twitter Instant Win campaign was launched just as the
                      brand launched in January 2020.&nbsp;Launched as part of a
                      promotional campaign for a newly launched brand with a
                      limited budget.&nbsp;In just two weeks, the 40 followers
                      have grown to over 2,960.
                    </p>
                    <div className="case__data-container">
                      <div className="case__data-box">
                        <p className="case__dtitle">
                          Number of followers increased
                        </p>
                        <p className="case__data">
                          2,934
                          <span className="case__unit">followers</span>
                        </p>
                      </div>
                      <div className="case__data-box">
                        <p className="case__dtitle">CPF</p>
                        <p className="case__data">
                          2.22
                          <span className="case__unit">yen*</span>
                        </p>
                        <small className="case__notes">
                          *Including gift cost
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="case__item">
                  <div className="case__head">
                    <div className="case__img-wapper">
                      <StaticImage
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="Images of case studies"
                        src="../../images/index/case-nor.png"
                        maxwidth="155px"
                      />
                    </div>
                  </div>
                  <div className="case__body">
                    <h3 className="case__title">
                      NOR. <span className="case__small">Co.</span>
                    </h3>
                    <p className="case__text">
                      NOR., a new branded cosmetics brand in its first year of
                      operation, had run several self-managed campaigns in the
                      past, gaining 6,920 followers, but the lottery was
                      struggling with operating costs, such as communicating
                      with the winners.&nbsp;Therefore, we implemented a
                      campaign using PARKLoT, which resulted in a record number
                      of followers and a significant reduction in operating
                      costs.
                    </p>
                    <div className="case__data-container">
                      <div className="case__data-box">
                        <p className="case__dtitle">
                          Number of followers increased
                        </p>
                        <p className="case__data">
                          4,270
                          <span className="case__unit">followers</span>
                        </p>
                      </div>
                      <div className="case__data-box">
                        <p className="case__dtitle">CPF</p>
                        <p className="case__data">
                          0.7
                          <span className="case__unit">yen*</span>
                        </p>
                        <small className="case__notes">
                          *Including gift cost
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="price index">
            <div className="price__container l-container">
              <h2 className="index__title">Charge</h2>
              <p className="index__lead price__lead">
                Recommended Instant Win campaign plans&nbsp;
                <br />
                are available for a variety of marketing initiatives.
              </p>
              <div className="price-card__container">
                <div className="price-card">
                  <h3 className="price-card__title--hot">
                    Want to increase your marketing effectiveness
                  </h3>
                  <div className="price-card__head--hot">
                    <p className="price-card__plan">Fixed price plan</p>
                    <p className="price-card__hot-price">
                      <strong className="price-card__hot-price--strong">
                        50,000{" "}
                      </strong>
                      yen~
                      <span className="price-card__unit">/month</span>
                    </p>
                    <p className="price-card__taxin">
                      (55,000 yen~ including tax)
                    </p>
                    <ul className="price-card__list">
                      <li className="price-card__item">
                        3-month contract: 60,000 yen/month (66,000 yen including
                        tax)
                      </li>
                      <li className="price-card__item">
                        6-month contract: 55,000 yen/month (60,500 yen including
                        tax)
                      </li>
                      <li className="price-card__item">
                        Annual contract: 50,000 yen/month (55,000 yen including
                        tax)
                      </li>
                    </ul>
                    <p className="price-card__notes">
                      * A minimum 3 month contract is required.
                    </p>
                  </div>
                  <div className="price-card__body">
                    <p className="price-card__initial">Initial cost</p>
                    <p className="price-card__cost--free">Free</p>
                  </div>
                </div>
                <div className="price-card">
                  <h3 className="price-card__title">
                    Want to try the campaign
                  </h3>
                  <div className="price-card__head">
                    <p className="price-card__plan">pay-as-you-go plan</p>
                    <p className="price-card__price">
                      <strong className="price-card__price--strong">
                        80,000{" "}
                      </strong>
                      yen
                      <span className="price-card__unit">/time</span>
                    </p>
                    <p className="price-card__taxin">
                      (88,000 yen including tax)
                    </p>
                  </div>
                  <div className="price-card__body">
                    <p className="price-card__initial">
                      Initial cost<small className="price-card__small">*</small>
                    </p>
                    <p className="price-card__cost">
                      <strong className="price-card__price--strong">
                        50,000{" "}
                      </strong>
                      yen
                    </p>
                    <p className="price-card__taxin">
                      (55,000 yen including tax)
                    </p>
                    <p className="price-card__notes">
                      *Only at the time of initial contract.
                    </p>
                  </div>
                </div>
              </div>
              <p className="index__lead">
                Please contact us for rates&nbsp;
                <br />
                for other campaigns.
              </p>
              <a
                className="ga-contact-price price__link"
                href="https://hub.park-lot.com/document-download"
                target="_blank"
                rel="noreferrer"
              >
                Inquire about rates
              </a>
            </div>
          </section>
          <section className="qa index">
            <div className="qa__container l-container">
              <h3 className="index__title">Frequently Asked Questions</h3>
              <div className="qa__box">
                <dl className="qa__list">
                  <dt className="qa__title">
                    Is it true that for this price you can run many
                    campaigns?&nbsp;Is there any additional cost?
                  </dt>
                  <dd className="qa__data">
                    If you sign up for a fixed price plan, you can campaign as
                    many times as you wish at no additional cost.
                  </dd>
                </dl>
                <dl className="qa__list">
                  <dt className="qa__title">
                    When is the earliest I can start the campaign?
                  </dt>
                  <dd className="qa__data">
                    If you have a Twitter account, campaign name, gift, campaign
                    period, and image for tweet submission, you can start your
                    campaign in as little as 10 minutes.
                  </dd>
                </dl>
                <dl className="qa__list">
                  <dt className="qa__title">
                    Can you deliver gifts to winning users on our behalf?
                  </dt>
                  <dd className="qa__data">
                    We do not provide delivery service to the winning
                    user.Winners' shipping information can be exported in CSV
                    format.
                  </dd>
                </dl>
              </div>
            </div>
          </section>
          <div className="contact">
            <p className="contact__text">
              Download the 3-minute PARKLoT&nbsp;
              <br />
              Success Storybook now!
            </p>
            <a
              className="ga-contact-middle contact__link btn-white"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              Download documents (free)
            </a>
          </div>
        </main>
      </Layout>
      {isShowFollowBtn && <FollowBtn />}
    </>
  )
}

export default Index
